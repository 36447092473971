import React from 'react'
import { graphql } from 'gatsby'
import { LinkLocale } from "../components/link-locale/link-locale"
import 'bootstrap/dist/css/bootstrap.min.css';
import {ArticlesListModel} from '../shared/models/articles-list.model'
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.scss"
import "../styles/main.scss"

import ArticlesList from '../components/articles-list/articles-list';
import ArticlesBreadcrumb from '../components/articles-breadcrumb/articles-breadcrumb';

class NewsAndEventsPage extends React.Component {

  render() {
    let page = this.props.data.allContentfulPage.edges[0].node;
    let artcilesListModel = {};
    artcilesListModel.sectionId = page.slug + "articles";
    artcilesListModel.articles = this.props.data.allContentfulSlotArticlesArticle.nodes;

    let articlesList = new ArticlesListModel(artcilesListModel);
    
    const { currentPage, numPages } = this.props.pageContext;
    const pathName = page.slug;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = `/${pathName}/` + (currentPage - 1 === 1 ? "" : (currentPage - 1).toString());
    const nextPage = `/${pathName}/` + (currentPage + 1).toString();


    return (
      <Layout bannerImage={page.headerImage} pageTitle={page.headerTitle}>
        <SEO title={page.title} description={page.description.description} pathname={page.slug} keywords={page.keywords} image={page.headerImage.file.url} />
        
        <section >
          <ArticlesBreadcrumb locale={page.node_locale}></ArticlesBreadcrumb>
          <ArticlesList data={articlesList}></ArticlesList>
        </section>
        <section className="d-flex align-items-center justify-content-end mb-5">
          {!isFirst ? (
            <LinkLocale url={prevPage} className="comvita-pagination-pre">

            </LinkLocale>
          ) : <div className="comvita-pagination-pre"></div>}
          <div className="comvita-pagination-number ">{currentPage} / {numPages}</div>
          {!isLast ? (
            <LinkLocale url={nextPage} className="comvita-pagination-next">

            </LinkLocale>
          ) : <div className="comvita-pagination-next"></div>}
        </section>

      </Layout >
    )
  }
}

export default NewsAndEventsPage

export const pageQuery = graphql`
  query NewsAndEventsPageTemplateQuery($locale: String!,$skip : Int!, $limit: Int!) {
    allContentfulPage(filter: {slug: {eq: "news-and-events"}, node_locale: {eq: $locale}}) {
      totalCount
      edges {
        node {
          id
          node_locale
          title
          keywords
          headerTitle
          description {
            description
          }
          slug
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid {
              base64
              ...GatsbyContentfulFluid_withWebp
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    allContentfulSlotArticlesArticle(filter: { node_locale: {eq: $locale}}, limit: $limit
    skip:$skip ) {
      nodes {
          publishedDate(formatString: "DD MMMM YYYY")
          title
          slug
          primaryImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
    }
  }
`


